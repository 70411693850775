<template>
	<div class="container">
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />
		</Head>

		<client-only>
			<component :is="'script'">
				(function (d, s, id, h) { var ftjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; var
				js = d.createElement(s); js.id = id; js.src = "https://cdn.formitable.com/sdk/v1/ft.sdk.min.js"; h &&
				(js.onload = h); ftjs.parentNode.insertBefore(js, ftjs); }(document, 'script', 'formitable-sdk',
				function () { FT.load('Analytics'); }));
			</component>
			<div
				class="ft-widget-b2"
				data-restaurant="a8608f4e"
				data-open="1500"
				data-open-mobile="false"
				data-color="#749779"
				:data-language="locale"
				data-tag="Website"
				data-toolbar="true"
				data-toolbar-mobile="true"
			></div>
		</client-only>

		<div class="mobile-socials">
			<social-media :socials="socials" />
		</div>
		<slot />
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, socials, fetchDefaults } = useDefaults();

if (!defaults.value) {
	await fetchDefaults();
}

await fetchPage();

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.fix-on-scroll');
	if (window.pageYOffset >= 50) {
		fixedElements.forEach((el) => el.classList.add('fixed'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('fixed'));
	}
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl || '',
	twitterTitle: metaTitle || '',
	twitterDescription: metaDescription || '',
	twitterCreator: website.hotelName || '',
	twitterImage: website.structuredImage || '',
	ogTitle: metaTitle || '',
	ogUrl: currentUrl || '',
	ogImage: website.structuredImage || '',
	ogDescription: metaDescription || '',
	ogSiteName: website.hotelName || '',
});

useHead({
	script: [
		{
			defer: true,
			'data-assistant': '676ae69b246811e32c043a7d',
			src: 'https://hiring-assistant.soigne.app/sdk.js',
		},
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@graph': [
					{
						'@type': 'Restaurant',
						'@id': `${config.public.siteUrl}#restaurant`,
						image: website.structuredImage,
						url: config.public.siteUrl,
						name: website.hotelName,
						logo: `${config.public.siteUrl}/images/logo-leonardos-raviolibar-full.png`,
						telephone: website.phone,
						email: website.email,
						address: {
							'@type': 'PostalAddress',
							streetAddress: `${website.street} ${website.number}, ${website.city}`,
							postalCode: website.postalCode,
						},
					},
				],
			}),
		},
	],
});
</script>
